<template>
    <div class="complete-get-started">
        <div class="white" :class="isMd ? 'pa-3' : 'pa-8'">
      <span class="darktext--text">{{
          profileNew.first_name + " " + profileNew.last_name
          }}</span>

            <p class="font-16 mt-2">
                <span class="darkprimary--text font-18">{{ $t('global.welcome') }}</span><br>
                {{ $t('completeProfile.welcomeDescription') }}
            </p>
            <div class="mt-10 d-flex align-center justify-space-between">
                <span class="blue--text cursor-pointer font-20"></span>
                <v-btn
                        rounded
                        color="register-primary"
                        dark
                        elevation="0"
                        @click="$router.push('/complete-profile/personal-info')"
                ><span class="white--text"> {{ $t('completeProfile.start') }} </span></v-btn
                >
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters(["profileNew"]),
    },
};
</script>

<style lang="scss">
.complete-get-started {
  &__img {
    img {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
}
</style>
